<template>
  <!-- 直播间商品管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="8" :sm="24">
                  <!-- 筛选 -->
                  <a-form-item label="商品名称">
                    <a-input v-model="queryParam.goodsName" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <a-col :md="16" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
                <a-col v-if="liveStatus == 1 && role == 2" :md="24" :sm="24" style="margin-bottom: 10px">
                  <a-button type="primary" @click="$refs.addForm.add({ liveId: $route.query.id })"
                    >添加直播间商品</a-button
                  >
                  <a-button
                    style="margin-left: 15px"
                    type="primary"
                    @click="$refs.deleteForm.deleteCommodity({ liveId: $route.query.id })"
                    >删除直播间商品</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :scroll="{ x: 1300 }" :loading="loading" :pagination="false">
            <!-- 商品图片插槽 -->
            <span slot="commodityImg" slot-scope="text, record">
              <img :src="record.coverImage" alt="" />
            </span>
            <!-- 规格类型插槽 -->
            <span slot="speType" slot-scope="text">
              {{ text == 1 ? '单规格' : text == 2 ? '多规格' : '' }}
            </span>
            <!-- 退货政策插槽 -->
            <span slot="returnGoodsPolicy" slot-scope="text">
              {{ text == 1 ? '七天退货' : text == 2 ? '不退货' : '' }}
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <span v-if="liveStatus == 1 && role == 2">
                <a-divider type="vertical" />
                <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => commodityDelete(record)">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 25px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 添加/删除直播间商品 -->
    <add-form @ok="initTableData" ref="addForm"></add-form>
    <delete-form @ok="initTableData" ref="deleteForm"></delete-form>
  </div>
</template>
<script>
import { liveCommodityList, liveCommodityAdd, liveCommodityDelete } from '@/api/modular/mallLiving/liveRoomAdmin'

import addForm from './addForm.vue'
import deleteForm from './deleteForm.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '封面图',
    dataIndex: 'coverImage',
    key: 'coverImage',
    align: 'center',
    scopedSlots: { customRender: 'commodityImg' },
  },
  {
    title: '商品售价',
    key: 'price',
    dataIndex: 'price',
    align: 'center',
  },
  {
    title: '销售量',
    key: 'sales',
    dataIndex: 'sales',
    align: 'center',
    width: '100px',
  },
  {
    title: '规格类型',
    key: 'speType',
    dataIndex: 'speType',
    align: 'center',
    scopedSlots: { customRender: 'speType' },
  },
  {
    title: '退货政策',
    key: 'returnGoodsPolicy',
    dataIndex: 'returnGoodsPolicy',
    align: 'center',
    scopedSlots: { customRender: 'returnGoodsPolicy' },
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    fixed: 'right',
    width: '220px',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  components: {
    addForm,
    deleteForm,
  },
  data() {
    return {
      data: [],
      loading: false,
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      liveStatus: '', //路由跳转带过来的直播状态
      role: '', //1管理员   2商家
      total: 0,
      classList: [], //顶部商品分类筛选数据
      queryParam: {},
    }
  },
  computed: {
    columns() {
      if (+this.role === 1) {
        return columns.slice(0, columns.length - 1)
      } else {
        return columns
      }
    },
  },
  mounted() {
    this.initTableData()
    this.liveStatus = this.$route.query.liveStatus
    this.role = this.$route.query.role
    console.log('直播状态', this.$route.query)
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    searchTableData() {
      this.initTableData()
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: {
          ...this.queryParam,
          id: this.$route.query.id,
        },
      }
      liveCommodityList(params)
        .then((res) => {
          if (res.success) {
            this.loading = false
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 单个删除直播间商品
    commodityDelete(record) {
      liveCommodityDelete({ id: this.$route.query.id, goodsIds: record.id }).then((res) => {
        if (res.success) {
          this.$message.success('商品删除成功！')
          this.getList()
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 30px;
  height: 30px;
}
</style>
