<template>
  <a-modal
    title="添加商品"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 商品选择下拉框，带搜索功能 -->
            <a-form-item label="选择商品" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select
                mode="multiple"
                v-decorator="['storeIdList', { rules: [{ required: true, message: '请选择要添加的商品' }] }]"
                show-search
                placeholder="请选择要添加的商品"
                option-filter-prop="children"
                :filter-option="false"
                @focus="handleFocus"
                @search="handleSearch"
              >
                <a-select-option 
                  v-for="item in allCommodityList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { 
  liveCommodityAdd,
  liveCommodityDelete
} from '@/api/modular/mallLiving/liveRoomAdmin'
import { 
  getCommodityList
} from '@/api/modular/mallLiving/commodity/commodityList'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      allCommodityList:[]
    }
  },

  methods: {
    // 初始化方法
    add(record) {
      this.visible = true
      this.id = record.liveId
    },
    //获得商家商品列表
    getCommodity(value){
      let param = {
        //page为空，查询所有
        page:{},  
        //不传值的话，value默认为undefined
        queryParam:{
          keywords: value  
        }
      }
      //调获取商品列表接口
      getCommodityList(param).then((res)=>{
        if(res.success){
          this.allCommodityList = res.data.rows
          console.log(this.allCommodityList)
        }
      })
    },
    // 输入框聚焦时候先调用一次获取商品列表接口
    handleFocus(){
      this.getCommodity()
    },
    //输入框搜索时的回调，将输入框的值传进去
    handleSearch(value){
      console.log(value)
      this.getCommodity(value)
    },
    // 提交
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        values.goodsIds = values.storeIdList.join(',')
        delete values.storeIdList
        console.log('values',values)
        if (!errors) {
            liveCommodityAdd({ id: this.id, ...values }).then((res) => {
              if(res.success){
                this.$message.success("商品添加成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false  //关闭加载状态
      this.allCommodityList = []
      this.form.resetFields() //重置表单.
    },
  },
  
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>